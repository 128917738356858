
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
/* STORE */
import {banks,remittance,countries,auth,payMethods,rates} from '@/store/namespaces';
import BanksMethods from '@/store/banks/methods/banks.methods'
import RemittanceMethods from '@/store/remittance/methods/remittance.methods'
import CountriesMethods from '@/store/countries/methods/countries.methods'
import AuthMethods from '@/store/auth/methods/auth.methods'
import PayMethodsMethods from '@/store/payMethods/methods/payMethods.methods'
import RatesMethods from '@/store/rates/methods/rates.methods'
/* CONSTANTS */
import { REMITTANCE_TYPES } from '../../constants/remittance.constants';
import {RATE_EMPTY_STATE} from '@/modules/rates/constants/rate.emptyState.contant'
/* INTERFACES */
import {BankAccount} from '@/modules/banks/interfaces/bankAccounts.interface'
import { DepositMethod } from '@/modules/payMethods/interfaces/depositMethods.interface';
import { User } from '@/modules/auth/interfaces/user.interface';
import { NewRemittance } from '../../interfaces/newRemittance.interface';
import { CountryCurrency } from '@/modules/countries/interfaces/countries.interface';
import { Bank } from '@/modules/banks/interfaces/bank.interface';
import { RateType } from '@/modules/rates/interfaces/rateType.interface';
import { RangeRate } from '@/modules/rates/interfaces/rangeRate.interface';
import { MaxAmmount } from '../../interfaces/limits.interface';
import { FullRate, Rate } from '@/modules/rates/interfaces/rate.interface';
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue'
import InputFile from '@/components/utilities/InputFile.vue'
import Loader from '@/components/utilities/Loader.vue';
import Calculator from '@/modules/remittance/components/Calculator.vue';
import StaticCalculator from '@/modules/remittance/components/StaticCalculator.vue';
import Toast from '@/components/utilities/Toast.vue'
import InfoModal from '@/components/utilities/InfoModal.vue'
/* FUNCTIONS */
import { currencyConvertion, findDefaultRate, findDefaultRateType, findSpecificRate, manualRateNames, validateRemittanceRate, validateRemittanceRateFromDestiny } from '@/modules/rates/functions/rates.functions';
import {randomItem} from '@/utils/global-functions'
/* VUELIDATE */
import { required, requiredIf } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { MANUAL_RATES, RATE_CATEGORIES, RATE_TYPES } from '@/modules/rates/constants/rate.constants';
import { showToast } from '@/utils/global-functions';
import { PayMethod } from '@/modules/payMethods/interfaces/payMethods.interface';
import { EnvironmentConstants } from '@/constants/enviromentConstants';
@Component({
    components: {
        InputFile,
        SvgIcon,
        Loader,
        Calculator,
        StaticCalculator,
        Toast,
        InfoModal
    },
    mixins: [validationMixin],
    validations:{
        newRemittance:{
            originCountry:{idCountry:{required}},
            beneficiariesInfo:{destinationCountry:{idCountry:{required}}},
            depositMethod:{id_pay_method:{required}},
            bank:{idBank:{required}},
            holder:{idBankAccount:{required}}
        }
    }
})
export default class RemittanceData extends Vue {
    $refs : any = {};
    rateInfo : {rateType: RateType | null, rangeRate: RangeRate | null} = {rateType: null, rangeRate: null}
    remittanceTypes = REMITTANCE_TYPES;
    infoModal={
        open: false,
        title: ''
    }
    depositMethods: PayMethod[] = []
    banksByPayMethod: Bank[] = []
    currentbankAccounts: BankAccount[] = []
    disableCalcs = false;
    writingInOriginValGlobal = true;

    /* MODAL */
        informationModalStatus(newVal: boolean){
            this.infoModal.open = newVal;
        }
        goToChat(){
            this.$router.push({name:"Chat"}).catch();
            this.infoModal.open = false;
        }
    /* END MODAL */

    /* COMPUTED */
    get showBankOption(){
        return (!this.isEuropeVersion && this.newRemittance.countryCurrency.isoCode !== 'CLP') || ( this.newRemittance.countryCurrency.isoCode === 'CLP' && this.newRemittance.depositMethod.name === 'Transferencia Bancaria') || ( this.isEuropeVersion && this.newRemittance.depositMethod.name === 'Transferencia (SEPA instant)')
    }

    async getBankFees(data:{id_bank: number, id_country: number,id_pay_method:number,amount_fee: number,percent_fee: number,email_user: string}){
        // await this.getAllRates()
        if (data.amount_fee !== null){
            this.newRemittance.percentFee = false;
            this.newRemittance.amountFee = true;
            this.newRemittance.totalComission = data.amount_fee;
            this.newRemittance.totalOriginRemittance = this.newRemittance.totalDeposited - data.amount_fee;
        }else if (data.percent_fee !==null){
            this.newRemittance.percentFee = true;
            this.newRemittance.amountFee = false;
            this.newRemittance.totalComission = data.percent_fee;
            this.newRemittance.totalOriginRemittance =  this.newRemittance.totalDeposited - (this.newRemittance.totalDeposited * data.percent_fee /100)
        }else {
            this.newRemittance.percentFee = false;
            this.newRemittance.amountFee = false;
        }
        await this.calculateAmounts('getBankFees')
    }

    async saveUpdatedRate(newVal: {type: string, rates: any, old_id_rate: number}){
        if (this.userRates.type === RATE_CATEGORIES.MANUAL && newVal.type === RATE_CATEGORIES.MANUAL){
            let userRates = this.userRates;
            if (userRates.rates.find(el=> el.id_manual_rate === newVal.old_id_rate)){
                userRates.rates.map(el=>{
                    if (el.id_manual_rate === newVal.old_id_rate){ 
                        el.id_manual_rate = newVal.rates.id_manual_rate;
                        el.rate_factor = newVal.rates.rate_factor;
                    }
                })
                let firstCall = true;
                if (this.newRemittance.rateValue.id_rate_type === newVal.rates.id_rate_type) firstCall = false;
                await this.saveUserRates({rates : userRates.rates, type: userRates.type, firstCall: firstCall});
            }
        }
        else if (this.userRates.type === RATE_CATEGORIES.AUTOMATIC && newVal.type === RATE_CATEGORIES.AUTOMATIC){
            let userRates = this.userRates
            if (userRates.rates.find(el=> el.id_rate === newVal.rates.id_rate)){
                userRates.rates.map(el=>{if (el.id_rate === newVal.rates.id_rate){ el = newVal.rates}})
                let firstCall = true
                if (this.newRemittance.rateValue.id_rate_type === newVal.rates.id_rate_type) firstCall = false
                await this.saveUserRates({rates : userRates.rates, type: userRates.type, firstCall: firstCall})
            }
        }
        else if (this.userRates.type === RATE_CATEGORIES.SPECIAL && newVal.type === RATE_CATEGORIES.SPECIAL && newVal.old_id_rate === this.userRates.rates.id_special_rate){
            await this.saveUserRates({rates : newVal.rates, type: newVal.type, firstCall: false})
        }
        else if (this.userRates.type === RATE_CATEGORIES.VIP && newVal.type === RATE_CATEGORIES.VIP && newVal.old_id_rate === this.userRates.rates.id_vip_rate){
            await this.saveUserRates({rates : newVal.rates, type: newVal.type, firstCall: false})
        }
        if (this.$refs.calculator) this.$refs.calculator.forceRateUpdate(this.newRemittance.rateValue)
        this.calculateAmounts('saveUpdatedRate');
        if (this.$route.params.modo === REMITTANCE_TYPES.WITH_CAPTURE){
            this.newRemittance.totalDeposited = this.newRemittance.captures.map(item => item.ammount).reduce((prev, curr) => prev + curr, 0);
            this.newRemittance.totalDepositedDestiny = currencyConvertion(this.newRemittance.rateValue,this.newRemittance.totalDeposited)
        }
    }

    /* WHEN CREATED, LISTEN TO SOCKETS */
        async mounted(){
            try{
                // if (this.$socket.$)
                if (this.$socket.client.hasListeners('get_bank_fee')) this.$socket.client.removeAllListeners('get_bank_fee')
                this.$socket.client.on('get_bank_fee',this.getBankFees);
                this.$socket.client.on('rate_change',this.saveUpdatedRate);
                /* SELECT THE DEFAULTS COUNTRIES */
                await this.setDefaultCountries();
            }catch(e){
            }
            finally{
            }
        }
    /* END. WHEN CREATED, LISTEN TO SOCKETS */

    /* RETURNS TRUE IF USER GOT A MANUAL RATE AND IS REACHING THE NEXT RATE. NORMAL -> PREFERENCIAL -> MAYORISTA */
        get newRate():{show: boolean,amount?: number}{
            if (this.$route.params.modo === REMITTANCE_TYPES.WITH_CAPTURE){
                return {show:false}
            }else {
                if (
                    (this.newRemittance.rateCategory === RATE_CATEGORIES.AUTOMATIC || this.newRemittance.rateCategory === RATE_CATEGORIES.MANUAL) 
                    &&
                    (this.userRates.rates.length >  1) // Es una tasa manual de tipo Normal, preferencial o mayorista
                    && 
                    this.rateInfo.rangeRate && this.rateInfo.rangeRate.upper_limit && (this.newRemittance.totalDeposited >= this.rateInfo.rangeRate.upper_limit*0.7)){
                    return {show:true,amount: this.$options.filters.currency(this.rateInfo.rangeRate.upper_limit)}
                }
                return {show:false}
            }
        }
    /* END. RETURNS TRUE IF USER GOT A MANUAL RATE AND IS REACHING THE NEXT RATE. NORMAL -> PREFERENCIAL -> MAYORISTA */


    updateWritingInOriginVal(newVal: boolean) {
        this.writingInOriginValGlobal = newVal;
    }

    /* CALCS */
        @Watch('newRemittance.totalDeposited')
        async totalDepositedChanged(newVal: number)
        {
            this.calculateAmounts('Watch de newRemittance.totalDeposited')
        }

        @Watch('newRemittance.totalDepositedDestiny')
        async totalDepositedDestinyChanged(newVal: number){
            if (this.newRemittance.totalComission !== 0) this.newRemittance.totalDestinationRemittance = currencyConvertion(this.newRemittance.rateValue,this.newRemittance.totalOriginRemittance)
            else this.newRemittance.totalDestinationRemittance = this.newRemittance.totalDepositedDestiny
        }

        @Watch('newRemittance.depositMethod')
        async depositMethodChanged(newVal: string){
            if (newVal === 'Webpay'){
                this.newRemittance.totalFeeCurrency = parseFloat((3 * this.totalRemittanceCurrency / 100).toFixed(0))
            }
        }

        async calculateAmounts(test: string){
            // borre esto this.newRemittance.totalDeposited = parseFloat(this.newRemittance.totalDeposited.toFixed(2));
            if (this.userRates.type === RATE_CATEGORIES.AUTOMATIC || (this.userRates.type === RATE_CATEGORIES.MANUAL && this.userRates.rates.length > 1)){
                if (this.writingInOriginValGlobal) this.rateInfo = validateRemittanceRate(this.newRemittance.countryCurrency.idCourrency,this.newRemittance.beneficiariesInfo.destinationCurrency.idCourrency,this.rateTypes,this.rangeRates,this.newRemittance.totalDeposited)
                else this.rateInfo = validateRemittanceRateFromDestiny(this.newRemittance.countryCurrency.idCourrency,this.newRemittance.beneficiariesInfo.destinationCurrency.idCourrency,this.rateTypes,this.rangeRates,this.newRemittance.totalDepositedDestiny,this.userRates.rates)
                this.newRemittance.rateType = this.rateInfo.rateType;
                if (!this.newRemittance.rateType) this.newRemittance.rateType = findDefaultRateType(this.rateTypes)
            }

            if (this.newRemittance.percentFee)
                this.newRemittance.totalOriginRemittance = this.newRemittance.totalDeposited - (this.newRemittance.totalDeposited * this.newRemittance.totalComission /100)
            else // if (this.newRemittance.amountFee)
                this.newRemittance.totalOriginRemittance = this.newRemittance.totalDeposited - this.newRemittance.totalComission;

            if (this.newRemittance.rateType){
                this.newRemittance.rateValue = this.findSpecificRate();
            } else {
                this.newRemittance.rateValue = this.findDefaultRate()// this.newRemittance.ratesByCountries.find(el=> el.rate_name === 'Normal');
            }

            // if (this.newRemittance.totalComission !== 0) this.newRemittance.totalDestinationRemittance = currencyConvertion(this.newRemittance.rateValue,this.newRemittance.totalOriginRemittance)
            // else this.newRemittance.totalDestinationRemittance = this.newRemittance.totalDepositedDestiny
            // borre esto this.newRemittance.totalDepositedDestiny = currencyConvertion(this.newRemittance.rateValue,this.newRemittance.totalDeposited)
            this.calculateTotalDepositedFromAnyToLocal();
        }

        //an emit from calculator
        updateOriginAmount(value: number){
            this.disableCalcs = true;
            this.newRemittance.totalDeposited = value
        }

        calculateTotalDepositedFromAnyToLocal(){
            if (this.isSendingFromOtherCountry){
                this.newRemittance.totalDepositedInLocalCurrency = currencyConvertion(this.newRemittance.localCurrencyRateValue,this.newRemittance.totalDeposited)
                this.newRemittance.totalOriginRemittanceInLocalCurrency =  currencyConvertion(this.newRemittance.localCurrencyRateValue,this.newRemittance.totalOriginRemittance)
            }else{
                this.newRemittance.totalDepositedInLocalCurrency = this.newRemittance.totalDeposited;
                this.newRemittance.totalOriginRemittanceInLocalCurrency = this.newRemittance.totalOriginRemittance;
            }
        }
    /* END CALCS */

/* RATES */

    findDefaultRate(){
        if (this.userRates.type === RATE_CATEGORIES.AUTOMATIC)
            return this.userRates.rates.find(el=> { 
                if (el.rate_type_name === 'Normal' 
                    && el.id_origin_country === parseInt(this.newRemittance.originCountry.idCountry)
                    && el.id_destiny_country === parseInt(this.newRemittance.beneficiariesInfo.destinationCountry.idCountry)
                    && el.id_currency_origin === this.newRemittance.countryCurrency.idCourrency
                    && el.id_currency_destiny === this.newRemittance.beneficiariesInfo.destinationCurrency.idCourrency
                    ) return el
            })
        else if (this.userRates.type === RATE_CATEGORIES.MANUAL){
            //if (rates.rates.length > 1)
              if (this.userRates.rates.length > 1){
                return this.userRates.rates.find(el=> { 
                    if (el.rate_type_name === 'Normal' 
                        && el.id_origin_country === parseInt(this.newRemittance.originCountry.idCountry)
                        && el.id_destiny_country === parseInt(this.newRemittance.beneficiariesInfo.destinationCountry.idCountry)
                        && el.id_origin_currency === this.newRemittance.countryCurrency.idCourrency
                        && el.id_destiny_currency === this.newRemittance.beneficiariesInfo.destinationCurrency.idCourrency
                        ) return el
                })
            }
            else if (this.userRates.rates[0]) return this.userRates.rates[0]
            else return this.userRates.rates
            // else return rates.rates[0]
        }
        else if (this.userRates.type === RATE_CATEGORIES.VIP || this.userRates.type === RATE_CATEGORIES.SPECIAL)
            return this.userRates.rates
    }

     findSpecificRate(){
       if (this.userRates.type === RATE_CATEGORIES.AUTOMATIC)
            return this.userRates.rates.find(el=> { 
                if (el.id_rate_type === this.newRemittance.rateType.idRateType
                    && el.id_origin_country === parseInt(this.newRemittance.originCountry.idCountry)
                    && el.id_destiny_country === parseInt(this.newRemittance.beneficiariesInfo.destinationCountry.idCountry)
                    && el.id_currency_origin === this.newRemittance.countryCurrency.idCourrency
                    && el.id_currency_destiny === this.newRemittance.beneficiariesInfo.destinationCurrency.idCourrency
                    ) return el
            })
        else if (this.userRates.type === RATE_CATEGORIES.MANUAL){
              if (this.userRates.rates[0])
                return this.userRates.rates.find(el=> { 
                    if (el.id_rate_type === this.newRemittance.rateType.idRateType
                        && el.id_origin_country === parseInt(this.newRemittance.originCountry.idCountry)
                        && el.id_destiny_country === parseInt(this.newRemittance.beneficiariesInfo.destinationCountry.idCountry)
                        && el.id_origin_currency === this.newRemittance.countryCurrency.idCourrency
                        && el.id_destiny_currency === this.newRemittance.beneficiariesInfo.destinationCurrency.idCourrency
                        ) return el
                })
              else return this.userRates.rates
        }
     }

    get rateTypeName(){
        if (this.newRemittance.rateCategory === RATE_CATEGORIES.VIP)
               return 'Tasa Mejorada - Cliente Especial'
        else if (this.newRemittance.rateCategory === RATE_CATEGORIES.SPECIAL)
                return 'Tasa Mejorada - Promocional'
        else if (this.newRemittance.rateCategory === RATE_CATEGORIES.MANUAL){
            let manualRate = manualRateNames(this.newRemittance.rateType.name);
            if (manualRate) return 'Tasa '+manualRate.type+' - '+manualRate.name;
            else {
                if (this.userRates.rates[0])
                    manualRate = manualRateNames(this.userRates.rates[0].rate_type_name);
                else manualRate = manualRateNames(this.userRates.rates.rate_type_name);
                return 'Tasa '+manualRate.type+' - '+manualRate.name;
            }
        }
        else 'Tasa No Encontrada';
    }

    get isEuropeVersion(){
        return process.env.VUE_APP_EUROPE_VERSION || this.newRemittance.countryCurrency.isoCode === 'EUR'
    }

    async getAllRates(){
        this.$emit('loaderOn')
        await this.fetchUserRates({
            id_origin_country: parseInt(this.newRemittance.originCountry.idCountry),
            id_origin_currency: this.newRemittance.countryCurrency.idCourrency,
            id_destiny_country: parseInt(this.newRemittance.beneficiariesInfo.destinationCountry.idCountry),
            id_destiny_currency: this.newRemittance.beneficiariesInfo.destinationCurrency.idCourrency,
            email_user: this.userData.email_user
        })
        this.newRemittance.rateCategory = this.userRates.type;
        this.newRemittance.rateValue = this.findDefaultRate();
            if (this.userRates.type === RATE_CATEGORIES.AUTOMATIC || this.userRates.type === RATE_CATEGORIES.MANUAL)
                this.newRemittance.rateType = this.rateTypes.find(el => el.idRateType === this.newRemittance.rateValue.id_rate_type)
            else this.newRemittance.rateType = null;
        if (this.$route.params.modo === REMITTANCE_TYPES.WITH_CAPTURE){
            this.newRemittance.totalDeposited = this.newRemittance.captures.map(item => item.ammount).reduce((prev, curr) => prev + curr, 0);
            this.newRemittance.totalDepositedDestiny = currencyConvertion(this.newRemittance.rateValue,this.newRemittance.totalDeposited)
        }
        this.$emit('loaderOff')
    }

    async getRateFromLocalToAnyCountry(){
        this.newRemittance.totalDepositedInLocalCurrency=0;
        if (this.isSendingFromOtherCountry){
            let residCountry = this.originCountryCurrency[0];
            let fullRates : FullRate = await this.fetchFullRates({
                id_origin_country: this.newRemittance.originCountry.idCountry,
                id_origin_currency: this.newRemittance.countryCurrency.idCourrency,
                id_destiny_country: residCountry.id_country,
                id_destiny_currency: residCountry.id_currency,
                email_user: null,
                do_not_save: true
            })
            this.newRemittance.localCurrencyRateValue = fullRates.manualRates.find(el=> el.rate_type_name === MANUAL_RATES.PREFERENTIAL)
        }
    }

    get rateCurrency(){
        if (this.newRemittance.rateValue && this.newRemittance.rateValue.rate_factor !== null){
            return this.newRemittance.rateValue.rate_factor.toString().replace('.',',')
        }
        else {return '0,00'}
    }

    get rateName(){
        if (this.newRemittance.rateType) return this.newRemittance.rateType.name;
        else if (this.newRemittance.rateCategory === RATE_CATEGORIES.SPECIAL) 
            return this.newRemittance.rateValue.special_rate_name
        else if (this.newRemittance.rateCategory === RATE_CATEGORIES.VIP)
            return 'Especial VIP'
        else return null
    }
/* END RATES */

/* COUNTRIES */
    get isSendingFromOtherCountry(){
            if (parseInt(this.newRemittance.originCountry.idCountry) !== this.userData.id_resid_country) return true
            else return false
    }

    get originCountryCurrency(){
        return this.originCountriesCurrencies.filter(el=> el.id_country === this.userData.id_resid_country)
    }

    get originCountriesValidation(){
        // if (!this.validOwnExtTrans) {
        //     return this.originCountryCurrency
        // }
        // else return this.originCountriesCurrencies
        // NOW, IT'S NOT POSSIBLE TO CHANGE THE ORIGIN CURRENCY
        return []
    }
/* END COUNTRIES */

/* SELECT COUNTRIES */
    /* SET THE DEFAULT REMITTANCE COUNTRIES */
    async setDefaultCountries(refresh?: true) { 
        if (!this.newRemittance.originCountry.idCountry || refresh){
            this.newRemittance.originCountry={idCountry: this.originCountryCurrency[0].id_country.toString(),name: this.originCountryCurrency[0].viewing_name,code: this.originCountryCurrency[0].country_iso_code}
            this.newRemittance.countryCurrency={idCourrency: this.originCountryCurrency[0].id_currency,isoCode: this.originCountryCurrency[0].iso_cod}
        }
        if (!this.newRemittance.beneficiariesInfo.destinationCountry.idCountry || refresh){
            let defaultDestinyCountry: CountryCurrency = this.destinyCountriesCurrencies.find(el=> el.country_iso_code === 'VE');
            this.newRemittance.beneficiariesInfo.destinationCountry={idCountry: defaultDestinyCountry.id_country.toString(),name: defaultDestinyCountry.viewing_name,code: defaultDestinyCountry.country_iso_code}
            this.newRemittance.beneficiariesInfo.destinationCurrency={idCourrency: defaultDestinyCountry.id_currency,isoCode: defaultDestinyCountry.iso_cod};
        }
        await this.getAllRates();
        this.depositMethods = await this.fetchDepositMethods({id_country: this.newRemittance.originCountry.idCountry, id_currency: this.newRemittance.countryCurrency.idCourrency,only_pay: false});
        if (EnvironmentConstants.HOST.includes('https://api.bithonor.com/'))
        {
            this.depositMethods = this.depositMethods.filter(el => el.name !== 'Webpay')
        }
    }

    async selectDestinyCountry(countryCurrency: CountryCurrency){
        this.cleanBeneficiaries(countryCurrency)
        this.newRemittance.beneficiariesInfo.destinationCountry={
            idCountry: countryCurrency.id_country.toString(),
            name: countryCurrency.viewing_name,
            code: countryCurrency.country_iso_code,
        }
        this.newRemittance.beneficiariesInfo.destinationCurrency={idCourrency: countryCurrency.id_currency,isoCode: countryCurrency.iso_cod,}
        // this.sendDataToReceiveFee();
        await this.getAllRates();
        if (this.newRemittance.rateValue.rate_factor === 0){
            showToast('Ruta seleccionada no disponible','error');
            await this.setDefaultCountries(true)
        }
        else this.calculateAmounts('selectDestinyCountry')
    }

    async selectOriginCountry(countryCurrency: CountryCurrency){
        this.$refs.loader2.showLoader();
        this.resetFee();
        this.newRemittance.originCountry={
            idCountry: countryCurrency.id_country.toString(),
            name: countryCurrency.viewing_name,
            code: countryCurrency.country_iso_code,
        }
        this.newRemittance.countryCurrency={
            idCourrency: countryCurrency.id_currency,
            isoCode: countryCurrency.iso_cod,
        }
        this.depositMethods=[]
        this.banksByPayMethod=[]
        this.currentbankAccounts=[]      
        this.depositMethods = await this.fetchDepositMethods({id_country: countryCurrency.id_country, id_currency: countryCurrency.id_currency});
        if (EnvironmentConstants.HOST.includes('https://api.bithonor.com/'))
        {
            this.depositMethods = this.depositMethods.filter(el => el.name !== 'Webpay')
        }
        this.resetBankInfo(true);
        // this.sendDataToReceiveFee();
        await this.getAllRates();
        if (this.newRemittance.rateValue.rate_factor === 0){
            showToast('Ruta seleccionada no disponible','error');
            await this.setDefaultCountries(true)
        }else{
            await this.getRateFromLocalToAnyCountry();
            await this.fetchLimitsInOriginCountry({idCountry: parseInt(this.newRemittance.originCountry.idCountry),idVerifLevel: typeof this.userData.id_verif_level === 'number' ? this.userData.id_verif_level : parseInt(this.userData.id_verif_level) })
            this.calculateAmounts('selectOriginCountry');
        }
        this.$refs.loader2.hideLoader();
    }
/* END SELECT COUNTRIES */

    /* BENEFICIARIES */

        /* IF THE DESTINY CHANGED, CLEAN THE LIST OF BENEFICIARIES */
        cleanBeneficiaries(countryCurrency: CountryCurrency){
            if (
                (this.newRemittance.beneficiariesInfo.destinationCountry.idCountry.toString() !== countryCurrency.id_country.toString()) ||
                (this.newRemittance.beneficiariesInfo.destinationCurrency.idCourrency.toString() !== countryCurrency.id_currency.toString())
            ) this.newRemittance.beneficiariesInfo.beneficiaries = []
        }

    /* END. BENEFICIARIES */

/* FEE */
    resetFee(){
        this.newRemittance.percentFee = false;
        this.newRemittance.amountFee = false;
        this.newRemittance.totalComission = 0;
    }

    sendDataToReceiveFee(){
        if (this.newRemittance.bank.idBank && this.newRemittance.holder.idBank && this.newRemittance.depositMethod.id_pay_method)
            {
             this.$socket.client.emit('get_bank_fee',{
                email_user: this.userData.email_user,
                id_origin_bank: this.newRemittance.bank.idBank,
                id_destiny_bank: this.newRemittance.holder.idBank,
                id_pay_method: this.newRemittance.depositMethod.id_pay_method
            })
        }
    }
/* END FEE */

    get totalDepositedCurrency()
    {
        if (this.newRemittance.originCountry.code === 'CL')
        {
            this.newRemittance.totalDeposited = parseFloat(this.newRemittance.totalDeposited.toFixed(0));
        }
        return this.$options.filters.currency(this.newRemittance.totalDeposited) 
    }

    get totalFeeCurrency(){
        let fee  = 0
        if (this.newRemittance.depositMethod.name === 'Webpay')
            fee = parseFloat((3 * this.newRemittance.totalOriginRemittance / 100).toFixed(0))
        else if (this.newRemittance.percentFee)
            fee = this.newRemittance.totalDeposited * this.newRemittance.totalComission / 100;
        else // if (this.newRemittance.amountFee)
            fee = this.newRemittance.totalComission;
        return this.$options.filters.currency(fee)
    }

    get totalRemittanceCurrency(){
        let totalAmount
        if (this.newRemittance.depositMethod.name === 'Webpay')
            totalAmount = this.newRemittance.totalOriginRemittance + parseFloat((3 * this.newRemittance.totalOriginRemittance / 100).toFixed(0))
        else 
            totalAmount = this.newRemittance.totalOriginRemittance
        return this.$options.filters.currency(totalAmount)
    }

    get totalDestinyRemittanceCurrency(){
        return this.$options.filters.currency(this.newRemittance.totalDestinationRemittance)
    }

    get totalDepositedInLocalCurrencyFormatted(){
        return this.$options.filters.currency(this.newRemittance.totalDepositedInLocalCurrency)
    }


/* BANKS INFO */

    getFormattedHolderName(holderName: string){
        let words = holderName.split(" ");
        let lastWord =  words[words.length - 1];
        return holderName.slice(0,3)+'-'+lastWord
    }

    async getBanks(){
        this.banksByPayMethod = await this.fetchBanks(this.newRemittance.depositMethod.id_pay_method);
    }

    async selectBankAccount(bankAccount: BankAccount){
        this.resetFee()
        this.newRemittance.holder={idBankAccount: bankAccount.id_bank_account,idBank: bankAccount.id_bank,bankName: bankAccount.bank_name,holderName: bankAccount.account_holder_name,accountNumber: bankAccount.account_number, pay_method_viewing_name: bankAccount.pay_method_viewing_name}
        this.sendDataToReceiveFee();
    } 

    async selectBank(bank: Bank){
        this.resetFee()
        this.newRemittance.bank = bank;
        this.newRemittance.bank.idBank = bank.id_bank;
        await this.fetchBankAccounts({countryId: parseInt(this.newRemittance.originCountry.idCountry),currencyId: this.newRemittance.countryCurrency.idCourrency});
        this.currentbankAccounts = this.bankAccounts;
        if (this.currentbankAccounts && this.currentbankAccounts.length > 0 && (!this.isEuropeVersion || this.isEuropeVersion && this.newRemittance.depositMethod.name === 'Transferencia (SEPA instant)'))
        {
            let filteredAccounts = this.currentbankAccounts.filter(e => e.pay_method_name === this.newRemittance.depositMethod.name && e.id_bank === this.newRemittance.bank.idBank)
            if (filteredAccounts.length === 0) filteredAccounts = this.currentbankAccounts.filter(e => e.id_pay_method === this.newRemittance.depositMethod.id_pay_method)
            if (filteredAccounts.length === 1) await this.selectBankAccount(filteredAccounts[0])
            else if (filteredAccounts.length > 1){
                let randomBankAccount = randomItem(filteredAccounts)
                await this.selectBankAccount(randomBankAccount)
            }
            else {
                let randomBankAccount = randomItem(this.currentbankAccounts)
                await this.selectBankAccount(randomBankAccount)
            }
        }
        else if (this.currentbankAccounts && this.currentbankAccounts.length>0 && this.isEuropeVersion) {
            let randomBankAccount = this.currentbankAccounts.find(e => e.id_bank === bank.id_bank)
            if (!randomBankAccount)
                randomBankAccount = randomItem(this.currentbankAccounts)
            await this.selectBankAccount(randomBankAccount)
        }
        this.sendDataToReceiveFee();
    }

    async selectDepositMethod(depositMethod: PayMethod){
        this.newRemittance.depositMethod={
            name: depositMethod.name,
            id_pay_method: depositMethod.idPayMethod,
            id_country: depositMethod.idCountry,
            id_currency: depositMethod.idCurrency,
        }
        this.banksByPayMethod=[]
        this.currentbankAccounts=[]
        this.resetBankInfo();
        await this.getBanks();
        if (this.isEuropeVersion && this.newRemittance.depositMethod.name === 'Transferencia (SEPA)'){
            const defaultBank = this.banksByPayMethod.find(bank => bank.name === 'Banco Europeo')
            this.selectBank(defaultBank)
        }
        else if (this.newRemittance.depositMethod.name === 'Webpay') {
            const defaultBank = this.banksByPayMethod.find(bank => bank.name === 'Webpay')
            this.selectBank(defaultBank)
        }
        else{
            await this.fetchBankAccounts({countryId: parseInt(this.newRemittance.originCountry.idCountry),currencyId: this.newRemittance.countryCurrency.idCourrency});
            this.currentbankAccounts = this.bankAccounts;
            if (this.currentbankAccounts && this.currentbankAccounts.length>0){
                let randomBankAccount = randomItem(this.currentbankAccounts)
                await this.selectBankAccount(randomBankAccount)
            }
        }
    }

    resetBankInfo(refreshDeposit?: boolean){
        this.newRemittance.bank={idBank: null,name: ''};
        this.newRemittance.holder={idBankAccount: null,idBank:null,bankName: '',holderName: '',accountNumber: '',pay_method_viewing_name: null};
        if (refreshDeposit)
        this.newRemittance.depositMethod= {name:'', id_country:null, id_currency: null, id_pay_method:null};
    }
/* END BANKS INFO */

/* AMOUNTS */
    /* REMITTANCE MIN AMOUNT */
    get minAmountInOriginCountry(){
       // if (this.isSendingFromOtherCountry)
            return this.rangeRates.find(el=> el.id_currency_origin === this.newRemittance.countryCurrency.idCourrency && el.id_currency_destiny === this.newRemittance.beneficiariesInfo.destinationCurrency.idCourrency).min_amount
        //else return this.minAmmountInRemittance.value
    }

    /* MAX AMOUNTS  */
    get monthlyAmountInOriginCountry(){
            return this.limitsInOriginCountry.monthlyAmount
    }
    get weeklyAmountInOriginCountry(){
        return this.limitsInOriginCountry.weeklyAmount
    }

    /* ACUMS */
    get monthlyAcumInOriginCountry(){
        if (this.isSendingFromOtherCountry)
            return currencyConvertion(this.newRemittance.localCurrencyRateValue,this.monthlyAcum)
        else return this.monthlyAcum
    }
    get weeklyAcumInOriginCountry(){
        if (this.isSendingFromOtherCountry)
            return currencyConvertion(this.newRemittance.localCurrencyRateValue,this.weeklyAcum)
        else return this.weeklyAcum
    }
    get totalAcumInOriginCountry(){
        if (this.isSendingFromOtherCountry)
            return currencyConvertion(this.newRemittance.localCurrencyRateValue,this.totalAcum)
        else return this.totalAcum
    }

     amountsValidations() {
        // if (this.$route.params.modo === REMITTANCE_TYPES.WITH_CAPTURE){
        //     return true
        // }
        // let totalOriginRemittance = this.newRemittance.totalOriginRemittance;
        // if (this.isSendingFromOtherCountry) totalOriginRemittance = this.newRemittance.totalOriginRemittanceInLocalCurrency;
        if (this.newRemittance.totalOriginRemittance < this.minAmountInOriginCountry){
           this.infoModal={
               open: true,
               title: `El monto mínimo a enviar es de ${this.$options.filters.currency(this.minAmountInOriginCountry)}${this.newRemittance.countryCurrency.isoCode}`
           };
           return false 
        }
        else if (this.monthlyAmountInOriginCountry && 
                    ((this.newRemittance.totalOriginRemittance + this.weeklyAcumInOriginCountry) > this.weeklyAmountInOriginCountry)){
           this.infoModal={
               open: true,
               title: `El monto a enviar no puede superar el límite semanal de ${this.$options.filters.currency(this.weeklyAmountInOriginCountry)} ${this.newRemittance.countryCurrency.isoCode}`
           };
           return false
        }
        else if  (!this.monthlyAmountInOriginCountry && ((this.newRemittance.totalOriginRemittance + this.totalAcumInOriginCountry) > this.weeklyAmountInOriginCountry)){
            this.infoModal={
               open: true,
               title: `El monto a enviar no puede superar el límite de ${this.$options.filters.currency(this.weeklyAmountInOriginCountry)} ${this.newRemittance.countryCurrency.isoCode}. Si desea enviar más dinero, puede aumentar su nivel de Verificación a "Nivel Avanzado"`
           };
           return false
        }
        else if (this.monthlyAmountInOriginCountry !== null && ((this.newRemittance.totalOriginRemittance + this.monthlyAcumInOriginCountry) > this.monthlyAmountInOriginCountry)){
           this.infoModal={
               open: true,
               title: `El monto a enviar no puede superar el límite mensual de ${this.$options.filters.currency(this.monthlyAmountInOriginCountry)} ${this.newRemittance.countryCurrency.isoCode}`
           };
            return false
        }
        else if (this.newRemittance.totalDestinationRemittance <=0){
            this.infoModal={
               open: true,
               title: `El monto a a recibir debe ser mayor a 0,00 ${this.newRemittance.beneficiariesInfo.destinationCurrency.isoCode}`
           };
            return false
        }
        return true;
    }
/* END AMOUNTS */

    nextStep(){
        this.$v.$touch();
        if (!this.$v.$invalid && this.amountsValidations()){
            this.$emit('nextStep')
        }
    }

    /* REMITTANCE */
    @remittance.Getter(RemittanceMethods.getters.GET_NEW_REMITTANCE)
    newRemittance!: NewRemittance;
    @remittance.Getter(RemittanceMethods.getters.GET_VALID_OWN_EXT_TRANS)
    validOwnExtTrans!: boolean;
    // @remittance.Getter(RemittanceMethods.getters.GET_MAX_AMMOUNTS)
    // maxAmmounts!: MaxAmmount;
    @remittance.Getter(RemittanceMethods.getters.GET_WEEKLY_ACUM)
    weeklyAcum!: number;
    @remittance.Getter(RemittanceMethods.getters.GET_MONTHLY_ACUM)
    monthlyAcum!: number;
    @remittance.Getter(RemittanceMethods.getters.GET_TOTAL_ACUM)
    totalAcum!: number
    @remittance.Getter(RemittanceMethods.getters.GET_RESID_COUNTRY_CURRENCY)
    residCountryCurrency!: string;
    @remittance.Action(RemittanceMethods.actions.FETCH_WEEKLY_AND_MONTHLY_LIMITS_BY_COUNTRY)
    fetchLimitsInOriginCountry!: (data:{idCountry: number, idVerifLevel: number})=> Promise<boolean>
    @remittance.Getter(RemittanceMethods.getters.GET_WEEKLY_AND_MONTHLY_LIMITS_BY_COUNTRY)
    limitsInOriginCountry!: {weeklyAmount: number, monthlyAmount: number, countryId: number}
    /* BANKS */
    @banks.Action(BanksMethods.actions.FETCH_BANK_ACCOUNTS)
    fetchBankAccounts!:(data: any) => Promise<BankAccount[] |  undefined>
    @banks.Getter(BanksMethods.getters.GET_BANK_ACCOUNTS)
    bankAccounts!: BankAccount[];
    @countries.Getter(CountriesMethods.getters.GET_ORIGIN_COUNTRY_CURRENCY)
    originCountriesCurrencies!: CountryCurrency[];
    @countries.Getter(CountriesMethods.getters.GET_DESTINY_COUNTRY_CURRENCY)
    destinyCountriesCurrencies!: CountryCurrency[];
    @auth.Getter(AuthMethods.getters.GET_USER_DATA)
    userData!: User;
    @banks.Action(BanksMethods.actions.FETCH_BANKS_BY_PAY_METHOD)
    fetchBanks!:(payMethodId: number) => Promise<Bank[] | undefined>
    @payMethods.Action(PayMethodsMethods.actions.FETCH_PAY_METHODS_BY_COUNTRY)
    fetchDepositMethods!: (data: any) => Promise<PayMethod[] | undefined>;
    /* RATES */
    @rates.Getter(RatesMethods.getters.GET_MIN_AMMOUNT_IN_REMITTANCE)
    minAmmountInRemittance!: {value:number,currency:string}; 
    @rates.Getter(RatesMethods.getters.GET_RATE_TYPES)
    rateTypes!: RateType[];
    @rates.Getter(RatesMethods.getters.GET_RANGE_RATES)
    rangeRates!: RangeRate[];
    @rates.Action(RatesMethods.actions.SAVE_USER_RATES)
    saveUserRates!:(data:{rates:any, type: string, firstCall: boolean})=> Promise<boolean>
    @rates.Action(RatesMethods.actions.FETCH_USER_RATES)
    fetchUserRates!:(data: any) => Promise<boolean>
    @rates.Getter(RatesMethods.getters.GET_USER_RATES)
    userRates!: {type: string, rates: any}
    @rates.Getter(RatesMethods.getters.GET_USER_LOCAL_RATES)
    userLocalRates!: {type: string, rates: any}
    @rates.Action(RatesMethods.actions.SAVE_MIN_AMMOUNT_IN_REMITTANCE)
    saveMinAmountInRemittance!: (data: {value:number,currency:string}) => void
    @rates.Action(RatesMethods.actions.FETCH_FULL_RATES)
    fetchFullRates!: (data: any) => Promise<FullRate>
}

