import { Module } from 'vuex';
import RemittanceMethods from '@/store/remittance/methods/remittance.methods';
import { remittanceHttpRepository, RemittanceRepository }from '@/modules/remittance/repositories/remittance.repository';
import { REMITTANCE_EMPTY_STATE } from './remittance.state';
import { RemittanceStateInterface } from './interfaces/remittance.state.interface';
import { Remittance } from '@/modules/remittance/interfaces/remittance.interface';
import { NewRemittance } from '@/modules/remittance/interfaces/newRemittance.interface';
import { FrequentBeneficiary } from '@/modules/beneficiaries/interfaces/beneficiaries.interfaces';
import { NotificationType } from '@/modules/remittance/interfaces/notificationTypes.interface.interface';
import { NOT_NOTIFY } from '@/modules/remittance/constants/notification.constants';
import { Limit, MaxAmmount } from '@/modules/remittance/interfaces/limits.interface';
import { LIMIT_TYPES } from '@/modules/remittance/constants/limit.constants';
import { PreRemittance } from '@/modules/remittance/interfaces/preRemittance.interface';
import { getParams } from '@/utils/global-functions';
import { MinAmount } from '@/modules/remittance/interfaces/minAmount.interface';
import { Limitation, LimitationType } from '@/modules/verificationLevels/interfaces/limitations.interface';
import { RemittanceAcum } from '@/modules/remittance/interfaces/reports.interface';
import { convertToAmountFee } from '@/modules/remittance/functions/remittance.functions';
import { NEW_REMITTANCE_EMPTY_STATE } from '@/modules/remittance/constants/remittance.emptyState.constant';

const remittance: Module<RemittanceStateInterface, any> = {
    namespaced: true,
    state: REMITTANCE_EMPTY_STATE,
    getters: {
        [RemittanceMethods.getters.GET_CHAT_REMITTANCE](state): Remittance[] {
            return state.chatRemittances;
        },
        [RemittanceMethods.getters.GET_REMITTANCES](state): Remittance[] {
            return state.remittances;
        },
        [RemittanceMethods.getters.GET_NEW_REMITTANCE](state): NewRemittance {
            return state.newRemittance;
        },
        [RemittanceMethods.getters.GET_CURRENT_BENEFICIARIES](state): FrequentBeneficiary[] {
            return state.currentBeneficiaries;
        },
        [RemittanceMethods.getters.GET_NOTIFICATION_TYPES](state): NotificationType[] {
            return state.notificationTypes;
        },
        [RemittanceMethods.getters.GET_LIMITS](state): Limit[] {
            return state.limits;
        },
        [RemittanceMethods.getters.GET_MONTHLY_ACUM](state): number {
            return state.monthlyAcum;
        },
        [RemittanceMethods.getters.GET_WEEKLY_ACUM](state): number {
            return state.weeklyAcum;
        },
        [RemittanceMethods.getters.GET_TOTAL_ACUM](state): number {
            return state.totalAcum;
        },
        [RemittanceMethods.getters.GET_MAX_AMMOUNTS](state): MaxAmmount {
            return state.maxRemittanceAmmounts;
        },
        [RemittanceMethods.getters.GET_VALID_OWN_EXT_TRANS](state): boolean {
            return state.validOwnExtTrans;
        },
        [RemittanceMethods.getters.GET_VALID_THIRD_NAT_TRANSF](state): boolean {
            return state.validThirdNatTrans;
        },
        [RemittanceMethods.getters.GET_VALID_THIRD_EXT_TRANSF](state): boolean {
            return state.validThirdExtTrans;
        },
        [RemittanceMethods.getters.GET_RESID_COUNTRY_CURRENCY](state): string {
            return state.residCountryCurrency;
        },
        [RemittanceMethods.getters.GET_MAX_BENEFICIARIES_PER_REMITTANCE](state): number {
            return state.maxBeneficiariesPerRemittance;
        },
        [RemittanceMethods.getters.GET_PRE_REMITTANCE](state): PreRemittance {
            return state.preRemittance;
        },
        [RemittanceMethods.getters.GET_MIN_AMOUNTS](state): MinAmount[] {
            return state.minAmounts;
        },
        [RemittanceMethods.getters.GET_WEEKLY_AND_MONTHLY_LIMITS_BY_COUNTRY](state): {weeklyAmount: number, monthlyAmount: number, countryId: number} {
            return state.acumLimitsByCountry;
        },
        [RemittanceMethods.getters.GET_REMITTANCE_TOTALS_BY_MONTH](state): RemittanceAcum[] {
            return state.remittanceAcumByMonth;
        },
        [RemittanceMethods.getters.GET_REMITTANCES_BY_STATUS](state): {total: number, public_status: string}[] {
            return state.remittancesByStatus;
        },
        [RemittanceMethods.getters.GET_TOTAL_REMITTANCES](state): number {
            return state.totalRemittances;
        },
        [RemittanceMethods.getters.GET_REMITTANCES_QUANTITY_BY_MONTH](state): RemittanceAcum[] {
            return state.remittanceQuantityByMonth;
        },
        [RemittanceMethods.getters.GET_WEBPAY_INFO](state): {token: string, url: string} {
            return state.webpay;
        },
        
    },
    mutations: {
        [RemittanceMethods.mutations.SET_CHAT_REMITTANCE](state, remittances: Remittance[]): void {
            state.chatRemittances = remittances;
        },
        [RemittanceMethods.mutations.SET_REMITTANCE](state, remittances: Remittance[]): void {
            state.remittances = remittances;
        },
        [RemittanceMethods.mutations.SET_NEW_REMITTANCE](state, remittance: NewRemittance): void {
            state.newRemittance = remittance;
        },
        [RemittanceMethods.mutations.SET_CURRENT_BENEFICIARIES](state, beneficiaries: FrequentBeneficiary[]): void {
            state.currentBeneficiaries = beneficiaries;
        },
        [RemittanceMethods.mutations.SET_NOTIFICATION_TYPES](state, notificationType: NotificationType[]): void {
            state.notificationTypes = notificationType;
        },
        [RemittanceMethods.mutations.SET_LIMITS](state, limits: Limit[]): void {
            state.limits = limits;
            let maxAmmounts = limits.find(el=> el.ident_name = LIMIT_TYPES.MAX_REM_AMOUNT)
            state.maxRemittanceAmmounts = {
                monthlyAmmount: maxAmmounts.local_amount_2,
                weeklyAmmount: maxAmmounts.local_amount
            }
        },
        [RemittanceMethods.mutations.SET_MONTHLY_ACUM](state, monthlyAcum: number): void {
            state.monthlyAcum = monthlyAcum
        },
        [RemittanceMethods.mutations.SET_WEEKLY_ACUM](state, weeklyAcum: number): void {
            state.weeklyAcum = weeklyAcum
        },
        [RemittanceMethods.mutations.SET_TOTAL_ACUM](state, totalAcum: number): void {
            state.totalAcum = totalAcum
        },
        [RemittanceMethods.mutations.SET_VALID_OWN_EXT_TRANS](state, validOwnExtTrans: boolean): void {
            state.validOwnExtTrans = validOwnExtTrans
        },
        [RemittanceMethods.mutations.SET_RESID_COUNTRY_CURRENCY](state, residCountryCurrency: string): void {
            state.residCountryCurrency = residCountryCurrency
        }, 
        [RemittanceMethods.mutations.SET_VALID_THIRD_NAT_TRANSF](state, validThirdNatTrans: boolean): void {
            state.validThirdNatTrans = validThirdNatTrans
        },
        [RemittanceMethods.mutations.SET_VALID_THIRD_EXT_TRANSF](state, validThirdExtTrans: boolean): void {
            state.validThirdExtTrans = validThirdExtTrans
        }, 
        [RemittanceMethods.mutations.SET_MAX_BENEFICIARIES_PER_REMITTANCE](state, maxBeneficiariesPerRemittance: number): void {
            state.maxBeneficiariesPerRemittance = maxBeneficiariesPerRemittance
        }, 
        [RemittanceMethods.mutations.SET_PRE_REMITTANCE](state, preRemittance: PreRemittance): void {
            state.preRemittance = preRemittance
        },
        [RemittanceMethods.mutations.SET_MIN_AMOUNTS](state, minAmounts: MinAmount[]): void {
            state.minAmounts = minAmounts
        }, 
        [RemittanceMethods.mutations.SET_WEEKLY_AND_MONTHLY_LIMITS_BY_COUNTRY](state, acumLimitsByCountry: {weeklyAmount: number, monthlyAmount: number, countryId: number}): void {
            state.acumLimitsByCountry = acumLimitsByCountry
        }, 
        [RemittanceMethods.mutations.SET_REMITTANCE_TOTALS_BY_MONTH](state, remittanceAcumByMonth: RemittanceAcum[]): void {
            state.remittanceAcumByMonth = remittanceAcumByMonth
        }, 
        [RemittanceMethods.mutations.SET_REMITTANCES_BY_STATUS](state, remittancesByStatus: {total: number, public_status: string}[]): void {
            state.remittancesByStatus = remittancesByStatus
        },
        [RemittanceMethods.mutations.SET_TOTAL_REMITTANCES](state, totalRemittances: number): void {
            state.totalRemittances = totalRemittances
        },
        [RemittanceMethods.mutations.SET_REMITTANCES_QUANTITY_BY_MONTH](state, remittanceQuantityByMonth: RemittanceAcum[]): void {
            state.remittanceQuantityByMonth = remittanceQuantityByMonth
        },
        [RemittanceMethods.mutations.RESET_REMITTANCES_DATA](state): void {
            state.remittances = [],
            state.chatRemittances = [],
            state.preRemittance = {
                date:null,
                pre_remittance: null,
                active: null,
                was_expired: null,
                date_last_shown: null,
                id_pre_remittance: null
            },
            state.newRemittance = NEW_REMITTANCE_EMPTY_STATE,
            state.currentBeneficiaries = [],
            state.notificationTypes = [],
            state.limits = [],
            state.maxRemittanceAmmounts = {
                weeklyAmmount: 0,
                monthlyAmmount: 0
            },
            state.totalAcum = 0,
            state.weeklyAcum = 0,
            state.monthlyAcum = 0,
            state.residCountryCurrency = '',
            state.validOwnExtTrans = null,
            state.validThirdNatTrans = null,
            state.validThirdExtTrans = null,
            state.maxBeneficiariesPerRemittance = 0,
            state.minAmounts = [],
            state.acumLimitsByCountry = null,
            state.remittanceAcumByMonth = [],
            state.remittancesByStatus = [],
            state.totalRemittances = 0,
            state.remittanceQuantityByMonth = []
        }, 
        [RemittanceMethods.mutations.SET_WEBPAY_INFO](state, webpayInfo: {token: string, url: string}): void {
            state.webpay = webpayInfo
        },
    },
    actions: {   
        async [RemittanceMethods.actions.SEND_REMITTANCE]({ commit },remittance: NewRemittance): Promise<{error:boolean,remittancePubID?:string}> {
            try {
                remittance.totalComission = convertToAmountFee(remittance)
                let bodyFormData = new FormData();
                bodyFormData.append('remittance', JSON.stringify(remittance));
                if (remittance.captures[0]){
                    bodyFormData.append('firstCapture', remittance.captures[0].content);
                }
                if (remittance.captures[1])
                    bodyFormData.append('secondCapture', remittance.captures[1].content);
               let response : {remittancePubID:string,message:string} = await  remittanceHttpRepository.sendRemittance(bodyFormData);
               if (response.remittancePubID){
                   return {error: false, remittancePubID: response.remittancePubID}
               }
               return {error:true}
            } catch (e) {
                return {error:true}
            }
        },
        async [RemittanceMethods.actions.SEND_WEBPAY_REMITTANCE]({ commit },payload: { remittance: NewRemittance; token: string }): Promise<{error:boolean,remittancePubID?:string,transbankRes?: any}> {
            try {
                console.log('STORE payload',payload)
                // payload.remittance.totalComission = convertToAmountFee(payload.remittance)
                // let bodyFormData = new FormData();
                // bodyFormData.append('remittance', JSON.stringify(payload.remittance));
                // if (payload.remittance.captures[0]){
                //     bodyFormData.append('firstCapture', payload.remittance.captures[0].content);
                // }
                // if (payload.remittance.captures[1])
                //     bodyFormData.append('secondCapture', payload.remittance.captures[1].content);
                // bodyFormData.append('token', payload.token);
                let response : {remittancePubID:string,message:string, transbankRes: any} = await remittanceHttpRepository.sendWebpayRemittance(payload);
                if (response.remittancePubID){
                    return {error: false, remittancePubID: response.remittancePubID, transbankRes: response.transbankRes}
                }
                return {error:true}
            } catch (e) {
                return {error:true}
            }
        },
        async [RemittanceMethods.actions.SET_REMITTANCE]({ commit },remittance: NewRemittance): Promise<boolean> {
            try {
                commit(RemittanceMethods.mutations.SET_NEW_REMITTANCE,remittance)
                return true;
            } catch (e) {
                return false;
            }
        },
        async [RemittanceMethods.actions.SAVE_PRE_REMITTANCE]({ commit },remittance: NewRemittance): Promise<boolean> {
            try {
               let response : {message:string,id_pre_remittance: number} = await  remittanceHttpRepository.savePreRemittance(remittance)
               if (response.message === "Pre-remittance succesfully inserted."){
                   return true
               }
               return false
            } catch (e) {
                return false
            }
        },
        async [RemittanceMethods.actions.GET_PRE_REMITTANCE]({ commit },email_user: string): Promise<boolean> {
            try {
               let remittance : PreRemittance = await  remittanceHttpRepository.getPreRemittance(email_user)
                if (remittance.pre_remittance.email_user === email_user){
                   commit(RemittanceMethods.mutations.SET_PRE_REMITTANCE,remittance)
                   return true
                }
               return false
            } catch (e) {
                return false
            }
        },
        async [RemittanceMethods.actions.CANCEL_PRE_REMITTANCE]({ commit },preRemittanceId: number): Promise<boolean> {
            try {
               let remittance :{pre_remittance: PreRemittance} = await  remittanceHttpRepository.cancelPreRemittance(preRemittanceId)
                if (remittance.pre_remittance.id_pre_remittance === preRemittanceId){
                   commit(RemittanceMethods.mutations.SET_PRE_REMITTANCE,remittance.pre_remittance)
                   return true
                }
               return false
            } catch (e) {
                return false
            }
        },
        async [RemittanceMethods.actions.SET_PRE_REMITTANCE]({ commit },preRemittance: PreRemittance): Promise<boolean> {
            try {
                   commit(RemittanceMethods.mutations.SET_PRE_REMITTANCE,preRemittance)
                   return true
            } catch (e) {
                return false
            }
        },
        async [RemittanceMethods.actions.SAVE_CURRENT_BENEFICIARIES]({ commit },beneficiares: FrequentBeneficiary[]): Promise<void> {
            try {
                commit(RemittanceMethods.mutations.SET_CURRENT_BENEFICIARIES,beneficiares)
            } catch (e) {
            }
        },
        async [RemittanceMethods.actions.FETCH_CHAT_REMITTANCE]({ commit },data: any): Promise<boolean> {
            try {
               data.only_wholesale_partner =false;
               let params = '?' + getParams(data)
               let remittances : Remittance[] =  await remittanceHttpRepository.fetchRemittances(data.email_user,params);
               if (remittances === null){
                    remittances = []
                }
               commit(RemittanceMethods.mutations.SET_CHAT_REMITTANCE,remittances)
               return true
            } catch (e) {
                return false
            }
        },
        async [RemittanceMethods.actions.FETCH_REMITTANCES]({ commit },data: any): Promise<boolean> {
            try {
               data.only_wholesale_partner = false;
               let params = '?' + getParams(data)
               let remittances : Remittance[] =  await remittanceHttpRepository.fetchRemittances(data.email_user,params);
               if (remittances === null){
                   remittances = []
               }
               commit(RemittanceMethods.mutations.SET_REMITTANCE,remittances)
               return true
            } catch (e) {
                return false
            }
        },
        async [RemittanceMethods.actions.FETCH_NOTIFICATION_TYPES]({ commit }): Promise<boolean> {
            try {
               let notificationTypes : NotificationType[] =  await remittanceHttpRepository.fetchNotificationTypes();
               notificationTypes.unshift({
                   idNotificationType: null,
                   name: NOT_NOTIFY,
                   fields:[]
               })
               commit(RemittanceMethods.mutations.SET_NOTIFICATION_TYPES,notificationTypes)
               return true
            } catch (e) {
                return false
            }
        },
        async [RemittanceMethods.actions.FETCH_LIMITS]({ commit },publicCode: string): Promise<boolean> {
            try {
                let limits : {limits:Limit[],total_acum: number,weekly_acum: number,monthly_acum: number,currency: string} =  await remittanceHttpRepository.fetchLimitByUser(publicCode);
                let validOwnExtTrans = limits.limits.find(el => el.ident_name === LIMIT_TYPES.VALID_OWN_EXT_TRANSF).is_allowed
                let validThirdNatTrans = false;// limits.limits.find(el => el.ident_name === LIMIT_TYPES.VALID_THIRD_NAT_TRANSF).is_allowed
                let validThirdExtTrans = false;//limits.limits.find(el => el.ident_name === LIMIT_TYPES.VALID_THIRD_EXT_TRANSF).is_allowed
                let maxBenPerRemittance = limits.limits.find(el => el.ident_name === LIMIT_TYPES.DEST_BENEF).beneficiaries_num
                commit(RemittanceMethods.mutations.SET_VALID_OWN_EXT_TRANS,validOwnExtTrans)
                commit(RemittanceMethods.mutations.SET_VALID_THIRD_NAT_TRANSF,validThirdNatTrans)
                commit(RemittanceMethods.mutations.SET_VALID_THIRD_EXT_TRANSF,validThirdExtTrans)
                commit(RemittanceMethods.mutations.SET_LIMITS,limits.limits)
                commit(RemittanceMethods.mutations.SET_MONTHLY_ACUM,limits.monthly_acum)
                commit(RemittanceMethods.mutations.SET_WEEKLY_ACUM,limits.weekly_acum)
                commit(RemittanceMethods.mutations.SET_TOTAL_ACUM,limits.total_acum)
                commit(RemittanceMethods.mutations.SET_RESID_COUNTRY_CURRENCY, limits.currency)
                commit(RemittanceMethods.mutations.SET_MAX_BENEFICIARIES_PER_REMITTANCE,maxBenPerRemittance)
               return true
            } catch (e) {
                return false
            }
        },
        async [RemittanceMethods.actions.FETCH_MIN_AMOUNTS]({ commit }): Promise<boolean> {
            try {
                let minAmounts : MinAmount[] =  await remittanceHttpRepository.fetchMinAmounts();
                commit(RemittanceMethods.mutations.SET_MIN_AMOUNTS,minAmounts)
               return true
            } catch (e) {
                return false
            }
        },
        async [RemittanceMethods.actions.FETCH_WEEKLY_AND_MONTHLY_LIMITS_BY_COUNTRY]({ commit },data:{idCountry: number, idVerifLevel: number}): Promise<boolean> {
            try {
                let limitations : {limitations: Limitation[], name_limitations: LimitationType[]} =  await remittanceHttpRepository.fetchWeeklyAndMonthlyAmounts(data.idCountry);
                let limits = limitations.limitations.find(el=> el.id_verification === data.idVerifLevel && el.local_amount!==null)
                if (limits) { 
                    commit(RemittanceMethods.mutations.SET_WEEKLY_AND_MONTHLY_LIMITS_BY_COUNTRY,{weeklyAmount: limits.local_amount, monthlyAmount: limits.local_amount_2, countryId: data.idCountry})
                    return true
                }
                else  {
                    commit(RemittanceMethods.mutations.SET_WEEKLY_AND_MONTHLY_LIMITS_BY_COUNTRY,null)
                    return false
                }
            } catch (e) {
                commit(RemittanceMethods.mutations.SET_WEEKLY_AND_MONTHLY_LIMITS_BY_COUNTRY,null)
                return false
            }
        },
        async [RemittanceMethods.actions.FETCH_REMITTANCE_TOTALS_BY_MONTH]({ commit },data:{email_user: string, params:any}): Promise<boolean> {
            try {
                let params = '?'+getParams(data.params)
                let remittanceAcum : RemittanceAcum[]  =  await remittanceHttpRepository.fetchRemittanceTotalSendedByMonth(data.email_user,params)
                if (remittanceAcum === null) remittanceAcum = []
                commit(RemittanceMethods.mutations.SET_REMITTANCE_TOTALS_BY_MONTH,remittanceAcum)
                return true
            } catch (e) {
                return false;
            }
        },
        async [RemittanceMethods.actions.FETCH_REMITTANCES_BY_STATUS]({ commit },email_user: string): Promise<boolean> {
            try {
                let remittances : {byStatus:{total: number, public_status: string}[],totalRemittances: number}  =  await remittanceHttpRepository.fetchRemittancesByStatus(email_user)
                if (remittances.byStatus === null) remittances.byStatus = []
                commit(RemittanceMethods.mutations.SET_REMITTANCES_BY_STATUS,remittances.byStatus)
                commit(RemittanceMethods.mutations.SET_TOTAL_REMITTANCES,remittances.totalRemittances)
                return true;
            } catch (e) {

                return false;
            }
        },
        async [RemittanceMethods.actions.FETCH_REMITTANCES_QUANTITY_BY_MONTH]({ commit },data:{email_user: string, month: number}): Promise<boolean> {
            try {
                let params = '?month='+data.month
                let remittances : RemittanceAcum[]  =  await remittanceHttpRepository.fetchRemittancesQuantityByMonth(data.email_user,params)
                if (remittances === null) remittances = []
                commit(RemittanceMethods.mutations.SET_REMITTANCES_QUANTITY_BY_MONTH,remittances)
                return true
            } catch (e) {
                return false
            }
        },
        async [RemittanceMethods.actions.CREATE_WEBPAY_TRANSACTION]({ commit },data:{amount: number}): Promise<boolean> {
            try {
                let params = '?amount='+data.amount
                let webpayInfo : {token: string, url: string}  =  await remittanceHttpRepository.createWebpayTransaction(data.amount, params)
                console.log('webpayInfo',webpayInfo)
                commit(RemittanceMethods.mutations.SET_WEBPAY_INFO,webpayInfo)
                return true
            } catch (e) {
                return false
            }
        }
    },
};

export default remittance;
