export function hideEmail(email : string){
    if (email) {
        let splitEmail = email.split("@")
        let domain = splitEmail[1];
        let name = splitEmail[0];
        let nameLength = name.length;
        let asterisk = nameLength - 5;
        if (nameLength<=9) asterisk = nameLength - 2;
        let asteriskString = ''
        for (var i = 0; i < asterisk; i++) asteriskString += '*'
        return  name.substring(0,1).concat(asteriskString).concat(name.substring(nameLength-(nameLength<=9 ? 1 :2),nameLength)).concat('@').concat(domain)
    } 
    else
        return null
}

export function hideSomeCharacters(data: string,startQuantity: number,endQuantity: number){
    let dataLength = data.length;
    let asterisk = dataLength - startQuantity - endQuantity;
    let asteriskString = ''
    for (var i = 0; i < asterisk; i++) asteriskString += '*'
    return data.substring(0,startQuantity).concat(asteriskString).concat(data.substring(startQuantity+asterisk,data.length))
}

export function includesUppercade(value: string){
    var upperCaseLetters = /[A-Z]/g;
    if (value.match(upperCaseLetters)) return true
    return false
}


export function includesNumbers(value: string){
    var numbers = /[0-9]/g;
    if (value.match(numbers)) return true
    return false
}

export function hidePhoneNumber(phoneCode: string, phoneNumber : string){
    let codeLength = phoneCode.length;
    let asterisk = phoneNumber.slice(0, -3).length;
    let asteriskString = ''
    for (var i = 0; i < asterisk; i++) asteriskString += '*'

    return phoneCode.concat(asteriskString.concat(phoneNumber.substring(phoneNumber.length - 3, phoneNumber.length)))
}

export function passwordValidator(password: string){
    var numbers = /[0-9]/g;
    var upperCaseLetters = /[A-Z]/g;
    var length = 8;
    if (password.match(numbers) && password.match(upperCaseLetters) && password.length >=length)
        return true
    return false
}

export function capitalize(text: string){
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export function emailValidator(email: string){
    var mailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (mailFormat.test(email))
        return (true)
    return (false)
}

export function splitFullName(fullName: string){
    let name =  fullName.split(' ');
    let firstName = name[0];
    let secondName = fullName.substring(name[0].length+1).trim();
    return {firstName,secondName}
}

export function splitFullLastName(fullName: string){
    let lastname =  fullName.split(' ');
    let firstLastName = lastname[0];
    if (lastname.length > 2)
      firstLastName += ` ${lastname[1]}`
    const firstLastNameLength = firstLastName.length
    let secondLastName = fullName.substring(firstLastNameLength+1).trim();
    return {firstLastName,secondLastName}
}

/* Replace blank spaces with '-' */
export function replaceBlankSpaces(name: string) : string{
    return name.replace(/\s/g, '-')
}

/* Countries */

import {getCountry} from 'country-list-spanish';

/* Return Axios params string */
export function getParams(filter: {[key: string]:string}){
    let str = '';
    for(const i in filter){
        str += i + "=" + filter[i] + "&";
    }
    if(str.length) str = str.substring(0, str.length - 1);
    return str;
}


/* Obtain an array with spanish_name attribute witch has the spanish country name translated  */
export function getSpanishLanCountries(countries: Array<any>){
    let  spanishNames = countries;
    spanishNames.map(el =>{
       el.spanish_name = getCountry(el.country_iso_code)
    })
    return spanishNames
}

import Vue from 'vue';

export function showToast(message: string, type: string, dismissible?: boolean){
    Vue.$toast.open({
        message: message,
        type: type,
        duration: dismissible === true ? null : 3000, // El toast permanecerá visible hasta que se cierre manualmente
        dismissible: dismissible === true ? true : false, // Opcional: permite que el usuario cierre el toast
    });
}

import {GENDERS} from '@/utils/collections'

export function getGenderName(genderkey: string){
    let gender = ''
    GENDERS.forEach(el =>{
        if (el.key === genderkey) {
            gender = el.name
        }
    })
    return gender;
}

export function largestNumber(data: Array<any>,property: string){
    return  data.reduce((prev, current) => (prev[property] > current[property]) ? prev : current)[property]
}

export function smallestNumber(data: Array<any>,property: string){
    return  data.reduce((prev, current) => (prev[property] < current[property]) ? prev : current)[property]
}

export function sumElements(data: Array<any>,property?: string){
    return data.reduce((accumulator, object) => {
        if (!property) {accumulator + object;}
        else return accumulator + object[property];
      }, 0)
}

export function uniqElements(data: Array<any>,property: string):Array<any>{
    return data
     .map(e => e[property])
     .map((e, i, final) => final.indexOf(e) === i && i)
     .filter(obj=> data[obj])
     .map(e => data[e]);
}


export function replaceUrl(url: string){
    let newUrl = url.toLowerCase();
    newUrl = newUrl.replaceAll(' ','-')
    return newUrl
  }
  

export function countDecimals(value: number){
    if(Math.floor(value) === value) return 0;
    return value.toString().split(".")[1].length || 0; 
}

export function addZerosAtTheEnd(value: number){
    let result='0';
    value = Math.abs(value)
    for (let i=0;i<value-1;i++){
      result = result +'0'
    } 
    return result
  }

export function randomItem(items: Array<any>){
  return items[Math.floor(Math.random()*items.length)];
}