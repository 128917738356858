
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
/* STORE */
import { remittance, auth, banks } from '@/store/namespaces';
import RemittanceMethods from '@/store/remittance/methods/remittance.methods';
import AuthMethods from '@/store/auth/methods/auth.methods';
import BanksMethods from '@/store/banks/methods/banks.methods';
/* INTERFACES */
import { User } from '@/modules/auth/interfaces/user.interface';
import { NewRemittance } from '../../interfaces/newRemittance.interface';
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import Calculator from '@/modules/remittance/components/Calculator.vue';
import ConfirmationModal from '@/components/utilities/ConfirmationModal.vue';
import Loader from '@/components/utilities/Loader.vue';
/* DIRECTIVES */
import { VMoney } from 'v-money';
import { currencyConvertion } from '@/modules/rates/functions/rates.functions';
import { showToast } from '@/utils/global-functions';
/* CONSTANTS */
import { REMITTANCE_TYPES } from '../../constants/remittance.constants';
import { BankAccount } from '@/modules/banks/interfaces/bankAccounts.interface';
import { RATE_CATEGORIES } from '@/modules/rates/constants/rate.constants';

@Component({
    components: {
        Loader,
        SvgIcon,
        Calculator,
        ConfirmationModal,
    },
    directives: {
        money: VMoney,
    },
})
export default class AmmountsResume extends Vue {
    showContinueModal: boolean = false;
    $refs: any = {};
    remittanceConstants = REMITTANCE_TYPES;

    async mounted() {
        if (this.alreadyHasPaymentProof)
            await this.fetchBankAccount(
                this.newRemittance.holder.idBankAccount
            );
    }

    get alreadyHasPaymentProof() {
        if (this.$route.params.modo === REMITTANCE_TYPES.WITH_CAPTURE)
            return true;
        else return false;
    }

    get destinyTotal() {
        return this.$options.filters.currency(
            this.newRemittance.totalDepositedDestiny
        );
    }

    get totalDepositedCurrency() {
        return this.$options.filters.currency(
            this.newRemittance.totalDeposited
        );
    }

    get totalFeeCurrency() {
        let fee = 0;
        if (this.newRemittance.depositMethod.name === 'Webpay')
            fee = (3 * this.newRemittance.totalOriginRemittance) / 100;
        else if (this.newRemittance.percentFee)
            fee =
                (this.newRemittance.totalDeposited *
                    this.newRemittance.totalComission) /
                100;
        // if (this.newRemittance.amountFee)
        else fee = this.newRemittance.totalComission;
        return this.$options.filters.currency(fee);
    }

    get rateCurrency() {
        return this.$options.filters.currency(
            this.newRemittance.rateValue.rate_factor
        );
    }

    get totalDestinationRemittanceCurrency() {
        return this.$options.filters.currency(
            this.newRemittance.totalDestinationRemittance
        );
    }

    get totalOriginRemittanceCurrency() {
        return this.$options.filters.currency(
            this.newRemittance.totalOriginRemittance
        );
    }

    get rateName() {
        if (this.newRemittance.rateType)
            return this.newRemittance.rateType.name;
        else if (this.newRemittance.rateCategory === RATE_CATEGORIES.SPECIAL)
            return this.newRemittance.rateValue.special_rate_name;
        else if (this.newRemittance.rateCategory === RATE_CATEGORIES.VIP)
            return 'Especial VIP';
        else return null;
    }

    get paymentType() {
        let finalPaymentType = 'Indefinido';
        const code = this.newRemittance.transbankRes.data.payment_type_code;
        switch (code) {
            case 'VD':
            case 'VP':
                finalPaymentType = 'Débito';
                break;
            case 'VN':
            case 'S2':
            case 'SI':
            case 'NC':
            case 'VC':
                finalPaymentType = 'Crédito';
                break;
            default:
                break;
        }
        return finalPaymentType;
    }

    get installmentType() {
        let finalInstallmentType = 'No aplica';
        const code = this.newRemittance.transbankRes.data.payment_type_code;
        switch (code) {
            case 'VN':
            case 'S2':
            case 'SI':
            case 'NC':
                finalInstallmentType = 'Sin interés';
                break;
            case 'VC':
                finalInstallmentType = 'Con interés';
                break;
            default:
                break;
        }
        return finalInstallmentType;
    }

    get installmentsAmount() {
        return this.newRemittance.transbankRes.data.installments_amount ? this.newRemittance.transbankRes.data.installments_amount + ',00 ' + this.newRemittance.countryCurrency.isoCode : 'No aplica';	
    }
    

    beneficiaryAmmount(value: number) {
        return this.$options.filters.currency(value);
    }

    async continueRemittance() {
        if (this.newRemittance.depositMethod.name === 'Webpay') {
            this.$router
                .push({
                    path: `/enviar-dinero/envio-exitoso/AQUIVAELCODIGOPUB`,
                })
                .catch();
            return;
        } else {
            this.$emit('loaderOn');
            this.$refs.loader2.showLoader();
            let response = await this.sendRemittance(this.newRemittance);
            if (response.error) {
                showToast(
                    'Ha ocurrido un error al crear su operación',
                    'error'
                );
            } else {
                this.$router.push({ name: 'SendPreRemittanceSuccess' }).catch();
                this.$router
                    .push({
                        path: `/enviar-dinero/envio-exitoso/${response.remittancePubID}`,
                    })
                    .catch();
            }
            this.$emit('loaderOff');
            this.$refs.loader2.hideLoader();
        }
    }

    async nextStep() {
        // this.showContinueModal = true;
        this.$emit('setDisabled', true);
        await this.continueRemittance();
        this.$emit('setDisabled', false);
    }

    getPhoneNumber(phoneNumber: string) {
        let phoneArray = phoneNumber.split('-');
        if (phoneArray.length === 3) {
            return phoneArray[1] + phoneArray[2];
        }
        return '*********';
    }

    /* REMITTANCE */
    @remittance.Getter(RemittanceMethods.getters.GET_NEW_REMITTANCE)
    newRemittance!: NewRemittance;
    @remittance.Action(RemittanceMethods.actions.SEND_REMITTANCE)
    sendRemittance!: (
        remittance: NewRemittance
    ) => Promise<{ error: boolean; remittancePubID?: string }>;
    /* AUTH */
    @auth.Getter(AuthMethods.getters.GET_USER_DATA)
    userData!: User;
    /* BANK */
    @banks.Getter(BanksMethods.getters.GET_BANK_ACCOUNTS)
    bankAccounts!: BankAccount[];
    @banks.Action(BanksMethods.actions.FETCH_BANK_ACCOUNT)
    fetchBankAccount!: (bankAccountId: number) => Promise<boolean>;
    @banks.Getter(BanksMethods.getters.GET_BANK_ACCOUNT)
    bankAccount!: BankAccount;
}
